export default {
    api_Url:process.env.REACT_APP_API_URL,
    app_Url:process.env.REACT_APP_URL,
    auth_Url:process.env.REACT_APP_AUTH_URL,
    capt_Url:process.env.REACT_APP_CAPT_API_URL,
    mixpanel_Token:process.env.REACT_APP_PROJECT_TOKEN,
    payment_Id:process.env.REACT_APP_PAYMENT_ID,
    aws_Url:process.env.REACT_APP_AWS_API,
    server_error:process.env.REACT_APP_SERVER_ERROR,
    promoter_url:process.env.REACT_APP_PROMOTER_URl,
    edit_Url:process.env.REACT_APP_EDIT_URL,
    land_Page_url:process.env.REACT_APP_LANDING_PAGE,
    aws_sink_url:process.env.REACT_APP_AWS_BUCKET_SINK_URL,
    aws_src_url:process.env.REACT_APP_AWS_BUCKET_SRC_URL,
    aws_domain:process.env.REACT_APP_AWS_BUCKET_DOMAIN
}