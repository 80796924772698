import React from "react";
import { Navigate,Outlet,useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";

const PrivateLayout = () =>{
    const auth = useAuth();
    const location = useLocation();

    return auth?.authState?.isAuthenticated? <Outlet/>:<Navigate to='/' state={{from : location}} />
}
export default PrivateLayout;