import React, { useState } from 'react'
import { Player, Controls, ControlButtons, ControlIcons, ToolTip, ToolTipPlay, Option1, Option2, PlayWrap, Option3, Option4, Option5, RatePlay, Volume, PlaybackOptiondiv, PlaybackOption, VolumeBox, PlaybackSpeed, Time, Icon, VdoCntrlSection, GradientDiv } from '../../VideoPlayer.styled';
import ReactPlayer from 'react-player';
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import volumeBtn from "../../images/volume.svg"
import flonnect from "../../images/flonnect.svg";
import fullscreen from "../../images/fullscreen.svg";
import muteicon from "../../images/mute2.svg";
import pauseicon from "../../images/pause.svg";
import watchAgain from "../../images/rotate2.svg";
import WatchAgain from '../WatchAgain/watchAgain';
import _static from '../../../../../config/static';
import ReactGA from "react-ga4";
import { useVideo } from '../../Context/videoPlayerContext';
import { useAuth } from '../../../../../context/auth';
const PlayerContainer = (props) => {
  const { mouseHover, mouseLeave, control, PlayerContainerRef, onPlayPause, PlayRef, videoData, videoSuccess, userName, Reset,
    play, played, playing, muted, volume, handelSeekMouseDown, playbackRate, handleSeekChange, handleSeekMouseUp, handleMuted, handleVolumeChange, handleVolumeSeekUp,
    elapsedTime, totalDuration, setControl, setTip, tip, handleProgress, thumb, setThumbnail, setName, setIsHovering, toggleFullscreen,
    preventHorizontalKeyboardNavigation, embed } = props
  let { setstatePlay, statePlay } = props;
  let playRate = statePlay?.playbackRate;
  const [view, setView] = useState(false)
  const { authState } = useAuth()
  const handlePlaybackSpeedChange = (newSpeed) => {
    setstatePlay((prevState) => ({
      ...prevState,
      playbackRate: newSpeed,
    }));

  };
  const [showSlider, setShowSlider] = useState(false);

  const handlePlayEvent = (eventdata) => {
    if (!view) {
      const { category, videoId, flonnect_id, videoViewedByOthers } = eventdata;
      ReactGA.event(category, {
        videoId,
        flonnect_id,
        videoViewedByOthers
      });
      setView(true)
    }

  };
  const handlePlaybackSpeedClick = () => {
    setShowSlider(!showSlider); // Toggle the visibility state
  };
  const { app_Url,aws_sink_url,aws_domain } = _static;

  return (
    <Player
      onMouseLeave={mouseLeave}
      onMouseEnter={mouseHover}
      played={control}
      ref={PlayerContainerRef}
    >
      <div className="inDiv" onMouseDown={() => {
        onPlayPause()
        if (videoData?.email === authState?.user?.email) {
          handlePlayEvent({ category: 'video_play', videoId: videoData?.id, videoViewedByOthers: false, flonnect_id: authState?.user?.id ? 'flo'+authState?.user?.id : '' })
        } else {
          handlePlayEvent({ category: 'video_play', videoId: videoData?.id, videoViewedByOthers: true, flonnect_id: authState?.user?.id ? 'flo'+authState?.user?.id : '', })
        }
      }}>
        <GradientDiv />

        <ReactPlayer
          width={"100%"}
          height={"100%"}
          ref={PlayRef}
          url={
            videoSuccess
              ? videoData?.linkUrl?.includes(aws_domain) ? videoData.linkUrl?.replace(aws_sink_url, aws_sink_url) : `${aws_sink_url}/${userName}/${videoData?.linkUrl?.split("/")[3]?.split(".")[0]
                }`
              : videoData?.linkUrl
          }
          playing={playing}
          muted={muted}
          volume={volume}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          id="screen-video"
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous",
              }
            }
          }}
        />
        {thumb === true && videoData?.thumbnailUrl && videoSuccess ? (
          <img
            className="thumbnail"
            src={videoData?.thumbnailUrl?.replace(aws_sink_url, aws_sink_url)}
            alt=""
            draggable="false"
          />
        ) : (
          ""
        )}
      </div>

      {played !== 1 && (
        <Controls
          onMouseEnter={() => setControl(true)}
          played={control}
          className="controls"
          onMouseLeave={() => {
            setShowSlider(false);
          }}
        >
          <ControlButtons played={control} className="controlbtn">

            {/* start */}

            {videoSuccess && (
              <Box className="box" width={"100%"}>
                <Slider
                  className="pretto"
                  min={0}
                  max={100}
                  value={played * 100}
                  onChange={handleSeekChange}
                  onMouseDown={handelSeekMouseDown}
                  onChangeCommitted={handleSeekMouseUp}
                  sx={{
                    color: "#DCE1E9",
                    borderRadius: 0,

                    "& .MuiSlider-track": {
                      border: "none",
                      color: "#F31559",
                      borderRadius: 0,
                      height: 4,
                    },
                    "& .MuiSlider-thumb": {
                      width: "0px",
                      height: "0px",
                      borderRadius: 0,
                      backgroundColor: "white",
                      backgroundImage: "none",
                      "&:before": {
                        boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                      },
                      "&:hover, &.Mui-focusVisible, &.Mui-active": {
                        boxShadow: "none",
                      },
                    },
                  }}
                />
              </Box>
            )}

            {
              <VdoCntrlSection>

                <div>
                  {played !== 1 ? (
                    <ControlIcons
                      onMouseOver={() => setTip("play")}
                      onMouseLeave={() => setTip("")}
                    >
                      {tip === "play" && !embed ? (
                        <ToolTip>{playing ? "Pause" : "Play"}</ToolTip>
                      ) : (
                        ""
                      )}

                      {!playing ? (
                        <img
                          onClick={() => {
                            onPlayPause();
                            setThumbnail(false);
                            if (videoData?.email === authState?.user?.email) {
                              handlePlayEvent({ category: 'video_play', videoId: videoData?.id, videoViewedByOthers: false, flonnect_id: authState?.user?.id ? authState?.user?.id : '' })
                            } else {
                              handlePlayEvent({ category: 'video_play', videoId: videoData?.id, videoViewedByOthers: true, flonnect_id: authState?.user?.id ? authState?.user?.id : '', })
                            }
                            
                          }}
                          src={play}
                          alt=""
                          draggable="false"
                        />
                      ) : (
                        <img onClick={onPlayPause} src={pauseicon} alt="" draggable="false" />
                      )}
                    </ControlIcons>
                  ) : (
                    <ControlIcons
                      onMouseOver={() => setTip("watch")}
                      onMouseLeave={() => setTip("")}
                    >
                      {tip === "watch" && !embed ? <ToolTip>Replay</ToolTip> : ""}
                      <img onClick={Reset} src={watchAgain} alt="" draggable="false" />
                    </ControlIcons>
                  )}

                  {videoSuccess && (
                    <Time>
                      <div>{elapsedTime} </div> / <div>{totalDuration}</div>
                    </Time>
                  )}


                  <Icon src={flonnect} onClick={() => {
                    if (embed) {
                      window.open(`${app_Url}/dasboard/library`, '_blank')
                    }
                  }} />
                </div>


                <div>

                  <PlaybackSpeed playbackRate={playbackRate} onMouseOver={() => {
                    setTip("PlaybackSpeed");
                    // setShowSlider(true); 
                  }}

                    onMouseLeave={() => {
                      setTip("");
                      // setShowSlider(false); 
                    }} onClick={handlePlaybackSpeedClick}
                    showSlider={showSlider}>
                    {tip === "PlaybackSpeed" && !embed && !showSlider ? (
                      <ToolTipPlay >Playback Speed</ToolTipPlay>
                    ) : (
                      ""
                    )}
                    <PlayWrap >{playRate}<span>x</span></PlayWrap>
                    <PlaybackOptiondiv className='playSlider' onMouseLeave={() => {
                      setShowSlider(false);
                    }}>
                      <PlaybackOption>
                        <Option1 onClick={() => handlePlaybackSpeedChange(0.75)} selected={playRate === 0.75}><RatePlay>0.75<span>x</span></RatePlay></Option1>
                        <Option2 onClick={() => handlePlaybackSpeedChange(1.0)} selected={playRate === 1.0}><RatePlay>1<span>x</span></RatePlay></Option2>
                        <Option3 onClick={() => handlePlaybackSpeedChange(1.25)} selected={playRate === 1.25}><RatePlay>1.25<span>x</span></RatePlay></Option3>
                        <Option4 onClick={() => handlePlaybackSpeedChange(1.5)} selected={playRate === 1.5}><RatePlay>1.5<span>x</span></RatePlay></Option4>
                        <Option5 onClick={() => handlePlaybackSpeedChange(2.0)} selected={playRate === 2.0}><RatePlay>2<span>x</span></RatePlay></Option5>

                      </PlaybackOption>
                    </PlaybackOptiondiv>
                  </PlaybackSpeed>


                  <Volume
                    onMouseOver={() => setTip("volume")}
                    onMouseLeave={() => setTip("")}
                  >
                    {!muted ? (
                      <img
                        onClick={handleMuted}
                        src={volumeBtn}
                        alt=""
                        draggable="false"
                        onMouseOver={() => {
                          setIsHovering(true);
                          setName("volumebtn");
                        }}
                        onMouseOut={() => {
                          setIsHovering(false);
                          setName("");
                        }}
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <img
                        onClick={handleMuted}
                        src={muteicon}
                        alt=""
                        draggable="false"
                        onMouseOver={() => {
                          setIsHovering(true);
                          setName("muteicon");
                        }}
                        onMouseOut={() => {
                          setIsHovering(false);
                          setName("");
                        }}
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {/* {tip === "volume"&&!embed && !volumeChange ? (
                      <ToolTip className="mute">Mute</ToolTip>
                    ) : (
                      ""
                    )} */}
                    <VolumeBox className="volumeSlider">
                      <Box sx={{ height: 90 }}>
                        <Slider
                          className={!muted ? "slider" : "slider muted"}
                          min={0}
                          max={100}
                          value={volume * 100}
                          onChange={handleVolumeChange}
                          onChangeCommitted={handleVolumeSeekUp}
                          orientation="vertical"
                          aria-label="Temperature"
                          onKeyDown={preventHorizontalKeyboardNavigation}
                          sx={{
                            '& input[type="range"]': {
                              WebkitAppearance: "writing-mode",
                            },
                            color: "#5E5E5E",
                            borderRadius: 0,
                            padding: 0,

                            "& .MuiSlider-track": {
                              border: "none",
                              color: "#F0648C",
                              borderRadius: 0,
                              height: 5,
                            },
                            "& .MuiSlider-thumb": {
                              width: "15px",
                              height: "4px",
                              borderRadius: 0,
                              backgroundColor: "#FF658E",
                              "&:before": {
                                boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                              },
                              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                                boxShadow: "none",
                              },
                            },
                          }}
                        />
                      </Box>
                    </VolumeBox>

                  </Volume>




                  <ControlIcons
                    onMouseOver={() => setTip("fullscreen")}
                    onMouseLeave={() => setTip("")}
                    className="rightControl"
                  >
                    {tip === "fullscreen" && !embed ? (
                      <ToolTip>Fullscreen</ToolTip>
                    ) : (
                      ""
                    )}
                    <img
                      onClick={toggleFullscreen}
                      src={fullscreen}
                      alt=""
                      draggable="false"
                    />
                  </ControlIcons>

                </div>
              </VdoCntrlSection>
            }


          </ControlButtons>
        </Controls>
      )}
      {(played === 1) && (
        <WatchAgain Reset={Reset} />
      )}
    </Player>
  )
}

export default PlayerContainer