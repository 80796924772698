import styled, { keyframes } from "styled-components";
import theme from "../../../theme";
const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const HomeContainer = styled.div`
  * {
    font-family: "Inter", sans-serif;
  }
  background-color: white;
  position: relative;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* padding: 60px 60px 10px 63px; */
  padding: 28px 60px 10px 63px;
  justify-content: center;
  @media (min-width: 1220px) and (max-width: 1329px) {
    padding: 20px 15px;
  }
  &.video-player{
    ${theme.breakpoints.desktop} {
      padding: 20px 15px;
    
  }
  overflow-y:auto;
  }
`;
export const Comment = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9fb;
  border: 1px solid #e6e6e6;
  background: #fff;
`;
export const CommentCnt = styled.div`
  margin: 35px 0 35px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .input {
    position: relative;
    padding: 20px;
    display: flex;
    height: 27%;
  }
`;
export const CommentOpt = styled.div`
  column-gap: 24px;
  display: flex;
  align-items: center;
  height: 47px;
  color: ${theme.colors.lightText1};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-left: 20px;
  line-height: normal;
  border-bottom: 1px solid #e6e6e6;
  /* margin-bottom: 5px; */
  .advance-icons{
    display: none;
    ${theme.breakpoints.desktop} {
        display: flex;
      }
      ${theme.breakpoints.tab} {
      display: none;
      }
  }
  .comments {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid
      ${(props) =>
        props.active === "comments" ? theme.colors.button : "transparent"};
    /* padding-bottom: 3px; */
    height: 47px;
    /* margin-left: 16px; */
    .word {
      height: 24px;
      align-items: end;
      display: flex;
      /* align-items: center; */
      color: ${(props) =>
        props.active === "comments"
          ? theme.colors.lightText1
          : theme.colors.placeHolder};

      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      ${theme.breakpoints.desktop} {
        display: none;
      }
      ${theme.breakpoints.tab} {
      display: flex;
      }
    }
    .number {
      border-bottom: 3px solid transparent;
      padding-bottom: 3px;
      height: 41px;
      display: flex;
      align-items: center;
      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dfdbf4;
        color: #6056a4;
        margin-left: 11px;
        width: 27px;
        height: 24px;
        border-radius: 2.38px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .transcript {
    cursor: pointer;
    border-bottom: 1px solid
      ${(props) =>
        props.active === "transcript" ? theme.colors.button : "transparent"};

    height: 47px;
    display: flex;
    align-items: center;

    color: ${(props) =>
      props.active === "transcript"
        ? theme.colors.lightText1
        : theme.colors.placeHolder};
    /* margin-left: 16px; */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .word {
      height: 24px;
      display: flex;
      align-items: end;
      /* margin-top: 8px; */
      ${theme.breakpoints.desktop} {
        display: none;
      }
      ${theme.breakpoints.tab} {
      display: flex;
      }
    }
  }
  .manage {
    cursor: pointer;
    border-bottom: 1px solid
      ${(props) =>
        props.active === "manage" ? props.theme.colors.hover : "transparent"};
    /* padding-bottom: 3px; */
    height: 47px;
    display: flex;
    align-items: center;
    color: ${(props) => (props.active === "manage" ? "#121212" : "#888691")};
    .word {
      height: 24px;
      display: flex;
      align-items: end;
      /* margin-top: 8px; */
      ${theme.breakpoints.desktop} {
        display: none;
      }
      ${theme.breakpoints.tab} {
      display: flex;
      }
    }
  }
`;
export const CommentSec = styled.div`
  height: 95%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    width: 100%;
  }
  ::-webkit-scrollbar-thumb {
    display: ${(props) => (props.play === "in" ? "block" : "none")};
    background-color: #d9d9d9;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-button:vertical:decrement {
    height: 20px;
  }
  ::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 40px;
  }
  &.no-comments {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .transbtn {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
  &.transcript {
    position: relative;
    height: 90%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-track {
      width: 100%;
    }
    ::-webkit-scrollbar-thumb {
      display: ${(props) => (props.play === "in" ? "block" : "none")};
      background-color: #d9d9d9;
      border-radius: 8px;
    }

    ::-webkit-scrollbar-button:single-button {
      height: 20px;
    }
    ::-webkit-scrollbar-button:single-button:vertical:increment {
      height: 20px;
    }
  }
`;
export const CommentBox = styled.div`
  height: 92%;
  /* padding: 20px; */
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
   ${theme.breakpoints.tab} {
  height: 452px;
  }

  &.transcript {
  height:100%;

    .transbtn {
      padding: 24px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const CommentComp = styled.div`
  width: 96%;
  padding: 16px;
  padding-bottom: 20px;
  padding-top: 14px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-right: 8px;
  &.upgrade {
    padding: 22px 16px;
  }
  &.transcript {
    margin-bottom: 0;
    .transcontent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .content {
        height: 100%;

        .summary {
          display: flex;
          height: 100%;
          img {
            margin-right: 14px;
          }
          h6 {
            color: #334253;
            font-weight: 600;
            font-size: 16px;
            text-align: start;
            margin-top: 6px;
          }
        }

        p {
          color: #67727e;
          font-size: 16px;
          text-align: start;
          margin-top: 15px;
        }
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 14px;
      object-fit: cover;
    }
    .name {
      color: #334253;
      font-size: 16px;
      font-weight: 600;
      margin-right: 16px;
      text-align: start;
    }
    .time {
      color: #67727e;
      font-size: 16px;
      font-weight: 400;
    }
    .name-time {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .time-options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        img {
          margin-left: 3px;
          margin-right: 0;
          cursor: pointer;
        }
      }
    }
  }
  .comment {
    margin-top: 16px;
    color: #67727e;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
  }
`;
export const Info = styled.div`
  text-align: start;
  .heading {
    color: #334253;
    font-weight: 500;
    font-size: 16px;
    font-size: 19.36px;
    margin-bottom: 16px;
  }
  .content {
    color: #67727e;
    line-height: 24px;
    font-size: 16px;
    font-style: mixed;
    ul {
      margin-top: 4px;
      margin-bottom: 25px;
    }
    span {
      font-weight: 500;
    }
  }
`;
export const Generate = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  margin: 23px auto;
  width: 197px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 4px;
  cursor: pointer;
  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const CommentInput = styled.div`
  position: absolute;
  width: 95%;
  height: 189px;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 20px;
  bottom: 20px;
  right: 20px;
  left: 0;
  @media (max-height: 950px) {
    height: 100%;
  }
  textarea {
    width: 100%;
    height: 96px;
    border-radius: 8px;
    border: 1px solid #e9ebf0;
    padding: 12px 24px 0 24px;
    resize: none;
    &::placeholder {
      color: #67727e;
      font-size: 16px;
    }
    &:focus {
      outline: none;
      border: 1px solid #6c5dd3;
    }
  }
  .send {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export const TranscriptCnt = styled.div`
  width: 100%;
  color: #67727e;
  font-size: 16px;
  display: flex;
  margin-bottom: 20px;
  .time {
    padding-right: 40px;
    cursor: pointer;
  }
  .dialogue {
    width: 100%;
    text-align: start;
  }
`;

export const Indiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.background};
  justify-content: flex-start;

  &.videoPlayerSection {
    max-width: 1260px;
  }
  &.not-signed {
    align-items: center;
  }
`;
export const Feeds = styled.div`
  height: 100%;
  margin-left: ${(props) => (props.feature !== 3 ? "7.7%" : "auto")};
  margin-right: auto;
`;

export const Page = styled.div`
  margin: 2% auto 7% auto;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
`;
export const Player = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
  border-radius: 4px !important;
  &.capture-player{
    height:564px;
    background:black;
    ${theme.breakpoints.largeLapView} {
  height: 430px;
  }
  
  ${theme.breakpoints.lapView} {
  height:430px;
  }
  ${theme.breakpoints.tabViewCapt} {
    height: 354.118px;
  }
  ${theme.breakpoints.smallView} {
  height: 228px;
  }
  }
  video {
    margin: auto 0 !important;
  }
  .thumbnail {
    position: absolute;
    border-radius: 4px !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }
  .inDiv {
    border-radius: 3px !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  overflow: ${(props) => (props.played === true ? "visible" : "hidden")};
`;

export const ProcessingPageWrapper = styled.div`
  height: 28px;
  width: 100%;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  /* visibility: ${(props) =>
    props.processingCheck ? "visible" : "hidden"}; */

  p {
    color: #404b59;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
    margin-left: 16px;
  }
`;

export const ProcessingLoaderImg = styled.img`
  margin-left: 6px;

  animation: rotate 1s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Processingmsg = styled.div`
  color: ${theme.colors.placeHolder};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
`;
export const Controls = styled.div`
  width: 100%;
  height: 70px;
  /* margin:2% 0% ; */
  bottom: 0%;
  /* padding:0 0.5%; */
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  /* z-index: 98; */
  transition: all 0.1s ease-in-out;
  /* background-color:${(props) =>
    props.played === true ? "#222222" : "none"}; */
  transform: ${(props) =>
    props.played === true ? "translateY(0)" : "translateY(68%)"};
  background-color: transparent;
  /* background-color: red; */
  background: linear-gradient(180deg, rgba(30, 33, 38, 0) 0%, #1e2126 100%);

  .pretto {
    height: 5px;
    padding:9px 0px;
  }
`;
export const ToolTip = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  position: absolute;
  bottom: 27px;
  /* top: -40px; */
  /* position:fixed;
top:-70%; */
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 15px;

  &.mute {
    /* border: 1px white solid; */
    /* border-radius: 4px; */
    /* bottom: 10px; */
    /* top: 75%; */
    /* overflow: auto; */
  }
`;
export const ToolTipPlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  position: absolute;
  right: 50px;
  bottom: 41px;
  /* top: -40px; */
  /* position:fixed;
  top:-70%; */
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 15px;
`;
export const ControlButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;
export const Time = styled.div`
  display: flex;

  font-weight: 400;
  line-height: 14.52px;
  color: white;
  font-size: 12px;
  margin-left: 16px;
  div {
    /* width:30px; */
    &:first-child {
      margin-right: 2px;
    }
    &:last-child {
      margin-left: 2px;
    }
  }
`;

export const LeftControl = styled.div`
  width: 70%;
  display: flex;
  align-items: center;

  .box {
    display: flex;
  }
`;
export const ControlIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  position: relative;
  img {
  }
`;

export const RightControl = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const Volume = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: 16px;
  position: relative;
  .volumeSlider {
    /* z-index: -999; */
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    .volumeSlider {
      visibility: visible;
    }
  }

 
`;
export const VolumeBox = styled.div`
  background-color: #222222;
  border-radius: 3px;
  position: absolute;
  bottom: 190%;
  width: 32px;
  padding: 20% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .slider {
    color: #5d5d5d;
    &.muted {
      opacity: 0.5;
    }
  }
`;
export const PlayWrap = styled.div`
margin-right: 16px;
color: #FCFCFD;
text-align: right;
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
span{
  color: #FCFCFD;
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
}
`
export const PlaybackOptiondiv = styled.div`
  border-radius: 3px;
  position: absolute;
  bottom: 70%;
  /* padding: 20% 0; */
  right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PlaybackOption = styled.div`
  width: 223px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(33, 37, 45, 0.9);
  display: flex;
  column-gap: 6px;
  justify-content: center;

`;
export const Option1 = styled.div`
  /* margin:0 7px; */
  background-color: ${({ selected }) => (selected ? "#757575" : "transparent")};
  /* width: ${({ selected }) => (selected ? "36px" : "")}; */
  height: ${({ selected }) => (selected ? "18px" : "")};
  border-radius: ${({ selected }) => (selected ? "10px" : "")};
  justify-content: ${({ selected }) => (selected ? "center" : "")};
  padding: 3px 4px;
  /* padding : ${({ selected }) => (selected ? "3px 4px" : "")}; */
`;
export const Option2 = styled.div`
  /* margin:0 7px; */

  background-color: ${({ selected }) => (selected ? "#757575" : "transparent")};
  /* width: ${({ selected }) => (selected ? "36px" : "")}; */
  height: ${({ selected }) => (selected ? "18px" : "")};
  border-radius: ${({ selected }) => (selected ? "10px" : "")};
  justify-content: ${({ selected }) => (selected ? "center" : "")};
  padding: 3px 4px;

  /* padding : ${({ selected }) => (selected ? "3px 4px" : "")}; */
`;
export const Option3 = styled.div`
  /* margin:0 7px; */

  background-color: ${({ selected }) => (selected ? "#757575" : "transparent")};
  /* width: ${({ selected }) => (selected ? "36px" : "")}; */
  height: ${({ selected }) => (selected ? "18px" : "")};
  border-radius: ${({ selected }) => (selected ? "10px" : "")};
  justify-content: ${({ selected }) => (selected ? "center" : "")};
  padding: 3px 4px;

  /* padding : ${({ selected }) => (selected ? "3px 4px" : "")}; */
`;
export const Option4 = styled.div`
  /* margin:0 7px; */

  background-color: ${({ selected }) => (selected ? "#757575" : "transparent")};
  /* width: ${({ selected }) => (selected ? "36px" : "")}; */
  height: ${({ selected }) => (selected ? "18px" : "")};
  border-radius: ${({ selected }) => (selected ? "10px" : "")};
  justify-content: ${({ selected }) => (selected ? "center" : "")};
  padding: 3px 4px;

  /* padding : ${({ selected }) => (selected ? "3px 4px" : "")}; */
`;
export const Option5 = styled.div`
  /* margin:0 7px; */
  background-color: ${({ selected }) => (selected ? "#757575" : "transparent")};
  /* width: ${({ selected }) => (selected ? "36px" : "")}; */
  height: ${({ selected }) => (selected ? "18px" : "")};
  border-radius: ${({ selected }) => (selected ? "10px" : "")};
  justify-content: ${({ selected }) => (selected ? "center" : "")};
  padding: 3px 4px;

  /* padding : ${({ selected }) => (selected ? "3px 4px" : "")}; */
`;
export const RatePlay = styled.div`
  color: #fcfcfd;
  text-align: right;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  span {
    color: #fcfcfd;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`;
export const Icon = styled.img`
  margin-left: 16px;
`;
export const Details = styled.div`
  text-align: start;
  width: 100%;
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  background-color: #fff;
   ${theme.breakpoints.tabview} {
  height: 17%;
  }
  
  &.disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
export const Title = styled.div`
  color: ${(props) => props.theme.colors.title1};
  padding: 0px 90px 0px 30px;
  margin-top: 19px;
  display: flex;
  width: 100%;
  ${theme.breakpoints.tab} {
    padding: 0px 20px 0px 20px;

  }

  h1 {
    font-weight: 500;
    font-size: 22px;
    line-height: 29.05px;
    text-align: start;
   
  }
  input {
    text-align: start;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 4px;

    border: none;
    background: none;

    color: #404b59;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 600px;
    padding: 5px 5px 5px 5px;
    ${theme.breakpoints.tab} {
      font-size: 16px;
    

  }
    &:hover {
      border: 1px solid #e0e0e0;
    }
    &:focus {
      border: 1px solid #e0e0e0;
      outline: none;
      background: #fff;
    }
    &::selection {
      background: ${(props) => props.theme.colors.hover};
      color: #ffffff;
    }
  }
  p {
    font-size: 16px;
    ${theme.breakpoints.tab} {
      font-size: 14px;
    

  }
  }
  .input {
    border-radius: 4px;
    font-size: 16px;
    border-color: #d9d9d9;
    width: 100px;
  }
  .tags {
    display: flex;
    p {
      color: #086972;
      font-size: 16px;
      font-weight: 500;
      ${theme.breakpoints.tab} {
      font-size: 14px;
    

  }
    }
  }

  img {
    margin-left: 10px;
  }
  form {
    display: flex;
    width: 100%;
  }
`;
export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  z-index: 98;

  &.open-items {
    position: absolute;
    right: -1%;
    top: -10%;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .drop-items-wrap {
    display: flex;
    width: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    height: 30px;

    &:hover {
      background: #f8ffff;
      border: 1px solid #0a6973;
      border-radius: 4px;
    }
  }
  .drop-items {
    width: 150px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5%;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    .catogery-logo {
      margin-left: 10px;
    }
  }
`;

export const Detailbtn = styled.div`
  div {
    width: 150px;
    background: #f8ffff;
    border: 1px solid #0a6973;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5%;
    height: 30px;

    cursor: pointer;
    h1 {
      margin: 0;
      color: #086972;
      font-weight: 400;
      font-size: 14px;
    }
    .catogery-logo {
      margin-left: 10px;
    }
  }
`;
export const Menus = styled.div`
  width: 975px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1450px) {
    width: 820px;
  }
  &.view-page-menu {
    justify-content: flex-end !important;
  }
`;
export const MenuSection = styled.div`
  padding: 0;
  width: 73%;
  display: flex;

  border-radius: 4px;
  align-items: center;
  .line {
    border: 0.5px solid transparent;
    height: 20px;
    width: 0;
    margin: 0 7px;
    padding: 0;
  }
`;
export const MenuButton = styled.div`
  position: relative;
  background-color: #f8f8f9;
  border: 0.5px solid #e0e0e0;
  height: 50px;
  border-radius: 4px;
  padding: 2% 1%;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.title2};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 19.36px;
  img {
    position: absolute;
    right: -10.7px;
    top: -8.5px;
    width: 21.5px;
    height: 16.76px;
  }
  svg {
    margin-right: 11px;
  }
  &:hover {
    border: none;
    background-color: ${(props) => props.theme.colors.menuHover};
    color: ${(props) => props.theme.colors.hover};
    svg {
      path {
        fill: ${(props) => props.theme.colors.hover};
      }
    }
  }
`;
export const Share = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 23.4%;
  @media (max-width: 1430px) {
    width: 40%;
    justify-content: flex-end;
  }
`;
export const Dots = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43.2px;
  height: 47px;
  border: 0.5px solid #e0e0e0;
  background-color: white;
  cursor: pointer;
  border-radius: 4px;
  @media (max-width: 1430px) {
    margin-left: 20px;
  }
  &:hover {
    background-color: #f0eefb;
    border: none;
    svg {
      path {
        fill: #6a36f0;
      }
    }
  }
`;

export const ShareBtn = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  width: 165px;
  height: 50px;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4% 8.5%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.hover};
  }
  img {
    margin-right: 4%;
  }
  p {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const ShareComment = styled.button`
  font-size: 14px;
  font-weight: 600;
  padding: 12px 16px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #5e48e8;
  color: #5e48e8;

  &.cancel {
    border-color: transparent;
  }
  @media (max-height: 780px) {
    padding: 8px 12px;
  }
`;
export const CopyTranscript = styled.div`
  img {
    margin-right: 11.25px;
  }
  cursor: pointer;
  padding: 12px 16px;
  margin-right: 10px;
  width: 185px;
  color: #585763;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
    background-color: #f0eefb;
  }
  &.copied {
    color: ${(props) => props.theme.colors.hover};
    background-color: #f0eefb;
  }
`;

export const CommentKebab = styled.div`
  position: absolute;
  width: 149px;
  top: 90%;
  background-color: #656262;
  border-radius: 8px;
  padding: 10px 14px;
`;
export const CommentKebabCnt = styled.div`
  color: white;
  padding-bottom: 2%;
  font-size: 14px;
  text-align: start;
  cursor: pointer;
  &.disable-coment {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Functions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2%;
  .function-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
  }
`;

export const FunctionMenu = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  &.dots {
    justify-content: center;
    width: 10%;
  }
  &.save {
    justify-content: center;
    width: 20%;
  }
  img {
    margin: 0 4%;
  }
  h1 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
`;
export const Messagebox = styled.div`
  width: 82.9%;

  display: ${(props) =>
    props.display === 3 || props.display === 2 ? "none" : "block"};
`;
export const TextBox = styled.div`
  width: 100%;
  padding-bottom: 2%;
  position: relative;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .check-img {
    position: absolute;
    bottom: 35px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`;
export const ProfileId = styled.div`
  display: flex;
  /* justify-content: center;
align-items: center; */
  padding: 0px 90px 0px 35px;
  margin-top: 10px;

  color: #667085cc;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
${theme.breakpoints.tab} {
    padding: 0px 20px 0px 30px;

  }
  h1 {
    font-size: 16px;
    margin: 0 0 0 20px;
  }
  .profileDate {
    column-gap: 8px;
    display: flex;
  }
`;
export const TextArea = styled.textarea`
  width: 975px;
  padding: 1px 1%;
  resize: none;
  border: 1px solid white;
  border-radius: 4px;
  &.view-mode-text {
    margin-top: 40px;
  }
  &:hover {
    border: 1px solid #e0e0e0;
  }
  &:focus {
    outline: none;
    border: 1px solid #6a36f0;
  }
  &::selection {
    background-color: #086972;
    color: white;
    border: 1px solid blue;
  }
  &::placeholder {
    color: rgba(2, 2, 2, 0.3);
    font-size: 16px;
    font-weight: 500;
  }
`;
export const KebabMenu = styled.div`
  position: absolute;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  right: -50%;
  bottom: 66.2px;
  padding: 4% 2%;
  height: 148px;
  width: 190px;
  overflow-y: auto;
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
  background-color: white;
  z-index: 999;
  &.play-height {
    height: 200px;
  }
`;
export const KebabBox = styled.div`
  width: 100%;
  transition: all 0.2s ease-in-out;
`;
export const KebabItems = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  .premium {
    position: absolute;
    top: 0;
    right: 25%;
  }
  &.disableDetete {
    pointer-events: none;
    opacity: 0.2;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 36px;
    &.playlist {
      justify-content: flex-start;
    }
    align-items: center;
    .icon {
      width: 30%;
      display: flex;
      justify-content: center;
    }

    p {
      margin: 0;
      margin-left: 16px;
      font-size: 16px;
      .playlist {
        margin-left: 0;
      }
    }
  }
`;
export const BlackScreen = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.4s ease-in-out;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.library {
    background-color: black;
    opacity: 20%;
  }
  div {
    display: flex;
    color: white;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0 0 0 10px;
    }
  }
  .reloadIcon {
    height: 15%;
    width: 15%;
  }
`;
export const Shade = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 17px 18px -10px rgba(0, 0, 0, 0.4),
    inset 0px -11px 8px -10px #ccc;
`;
export const PlaylistBox = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  margin-top: 5px;
  &.playlist-items {
    &:hover {
      background-color: rgba(96, 86, 164, 0.06);
      p {
        color: #6a36f0;
      }
    }
  }
  &.back {
    margin-top: 10px;
    border-bottom: 0.5px solid #585763;
  }
  .icon {
    margin-left: 18px;
  }
  p {
    margin: 0;
    margin-left: 10px;
    &.playlist-items {
      margin-left: 16px;
    }
  }
`;
export const PlylistWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow-y: scroll;
`;

export const NoComments = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #9d9d9d;
`;
export const Upgrade = styled.div`
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  top: 0;
  bottom: 1%;
  right: 3.8%;
  left: 0;
  z-index: 10;
  border-radius: 40px;
  display: flex;
  align-items: flex-end;
`;

export const PremiumCnt = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contents {
    margin-bottom: 134px;
  }
  img {
    margin: auto;
    margin-bottom: 15.62px;
    width: 21.5px;
    height: 16.76px;
  }
  p {
    margin: 0;
    margin-bottom: 6px;
    color: #6056a4;
    line-height: 15px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const VdoContentWrapper = styled.div`
  width: 100%;
  /* height: 68%; */
  display: flex;
  justify-content: space-between;
  &.not-signed {
    justify-content: center;
  }
  ${theme.breakpoints.tab} {
      flex-direction: column;
      gap:20px;
  }
  ${theme.breakpoints.tabview} {
  height: 608px;
      
  }
`;

export const VdoContentWrapperInside = styled.div`
  height: 100%;
  width: 65.6%;
   ${theme.breakpoints.tab} {
      width: 100%;
    
  }
  
`;

export const VdoContentWrapperRight = styled.div`
  height: 100%;
  width: 31.8%;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fff;
  &.disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  ${theme.breakpoints.tab} {
   width: 100%;
  }
`;
export const VdoPlayerWrapper = styled.div`
  width: 100%;
  background: #282f3d;
  ${theme.breakpoints.tabview} {
  height: 83%;
  }
`;

export const VideoNameDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 5px 5px 5px 5px;
  color: #404b59;
  font-family: "Inter";
  font-size: 18px;
  text-align: start;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 18px;
    ${theme.breakpoints.tab} {
      font-size: 14px;

  }
    
  }
  img {
    cursor: pointer;
  }
`;

export const SummarySection = styled.div`
  width: 65.6%;
  /* height: 158px; */
  flex-shrink: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  ${theme.breakpoints.tab} {
  width: 100%;
  }
  form {
    margin-top: 17px;
  }
  &.disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const SummarySectionImg = styled.img``;
export const SummarySectionHeader = styled.div`
  color: #404b59;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Header = styled.div`
  margin-left: 8px;
`;

export const SummarySectionInput = styled.textarea`
  padding: 13px 30px 13px 30px;
  background-color: transparent;
  border: none;
  color: #667085;
  margin-bottom: 20px;
  margin-top: 10px;
  resize: none;
  overflow: auto;
  /* height: 100%; */
  width: 100%;
  height: 130px;

  &:focus {
    border-radius: 4px;
    outline: 1px solid #ccb7fc;
    background: #fff;
  }

  &:hover {
    border-radius: 4px;
    outline: 1px solid #ccb7fc;
    background: #fff;
  }

  &:active {
    border-radius: 4px;
    outline: 1px solid #ccb7fc;
    background: #fff;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid #ccb7fc;
    background: #fff;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
  }

  /* &::-webkit-scrollbar-track:hover {
            background-color: #555; 
        } */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
  }
`;
export const InfoIcon = styled.img`
  margin-left: 5px;
`;

export const SummarySectionDiv = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 30px;
`;
export const SummaryAutoSummary = styled.div`
  width: 161px;
  height: 17px;
  color: ${theme.colors.button};
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
  export const SummarySectionManualSummary = styled.div`
  height: 17px;

  color: ${theme.colors.placeHolder};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 6px;
  span {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const EmptyToolSectionImg = styled.img``;

export const EmptyToolSectionMsg = styled.div`
  color: #404b59;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 24px;
`;

export const InputCommentDiv = styled.div`
  width: 90.12%;
  min-height: 41px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #f4f6f8;
  /* position: absolute; */
  /* bottom: 1.9%; */
  align-items: center;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  outline: ${(props) =>
    props.activeTextArea ? `1.5px solid ${theme.colors.hoverBorder}` : "none"};
  background: ${(props) => (props.activeTextArea ? "#fff" : "#F4F6F8")};
  margin-bottom: 20px;
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    flex-direction: column;
  }
  button {
    height: 30px;
    width: 52px;
    color: #fff;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.13px;
    /* padding: 5px 5px; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
`;

export const TextAreaDiv = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.activeTextArea ? "space-between" : "flex-start"};
  cursor: pointer;
  /* background-color: gray; */
  border-top: ${(props) =>
    props.activeTextArea ? "0.5px solid  #DCE1E9" : "none"};
  .addText {
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
    display: ${(props) => (props.activeTextArea ? "none" : "flex")};
  }
`;

export const CommentTextarea = styled.textarea`
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 100%;
  width: 100%;
  resize: none;
  padding: 8px 12px 0px 0px;
  outline: none;
  border: none;
  display: ${(props) => (props.activeTextArea ? "flex" : "none")};
  height: 57px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
  }

  /* &::-webkit-scrollbar-track:hover {
            background-color: #555; 
        } */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
  }
`;

export const TextAreaImg = styled.img`
  height: 18px;
  width: 18px;
  border-radius: 50%;
`;
export const CommentBtn = styled.button`
  /* padding: 5px 10px; */
  align-items: flex-start;
  gap: 12.642px;
  border-radius: 5.057px;
  background: #5458f7;
  display: ${(props) => (props.activeTextArea ? "inline-flex" : "none")};
  color: #fff;
  border: none;
  /* padding:0px 10px; */
  height: 26px;
  width: 52px;

  &:focus {
    border: none;
  }
`;

export const CommentWrapper = styled.div`
  width: 100%;
  height: 75%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
  }

  /* &::-webkit-scrollbar-track:hover {
            background-color: #555; 
        } */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
  }
`;
export const CommentCard = styled.div`
  width: 90%;
  /* height: 97px; */
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #dce1e9;
  background: #fcfcfd;
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  padding: 15px;
  position: relative;

  &:hover {
    .commentKebab {
      display: flex;
    }
  }
`;

export const CommentItemsWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 15px;
`;
export const CommentItemImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const CommentName = styled.div`
  display: flex;
  align-items: center;
  color: #404b59;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const CommentTime = styled.div`
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  margin-left: 8px;
`;

export const UserComment = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  margin-top: 8px;
  word-break: break-all;
`;

export const CommentKebabOption = styled.img`
  position: absolute;
  right: 20px;
  top: 24px;
  cursor: pointer;
  display: none;
`;

export const TranscriptBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dce1e9;
  background: #fcfcfd;
  overflow-y: auto;
  padding: 15px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
  }

  /* &::-webkit-scrollbar-track:hover {
            background-color: #555; 
        } */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
  }
`;

export const TranscriptWrapper = styled.div`
  width: 100%;
  /* background-color: red; */
  display: flex;
  text-align: left;
  margin-bottom: 15px;

  cursor: pointer;
`;
export const TranscriptText = styled.div`
  margin-left: 28px;
  color: #404b59;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
`;
export const TranscriptTime = styled.div`
  width: 60px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  display: flex;
  align-items: center;
  color: #667085;
  justify-content: center;
  &:hover {
    background: #dce6ff;
    color: #9870fa;
  }
  &.current-time {
    background: #dce6ff;
    color: #9870fa;
  }
`;

export const ManageBox = styled.div`
  width: 100%;
  height: 91.3%;
`;

export const ManageExportWrap = styled.div`
  width: 100%;
  height: 161px;
  padding: 24px 5px 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ytSection {
    margin-top: 15px;
  }
`;

export const ManageExportHeader = styled.div`
  color: #404b59;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;

  .betaText {
    color: #6528f7;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.13px;
    width: 44px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #dce6ff;
    margin-left: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ManageExportItems = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  padding-left: 8px;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;

export const ManageItemText = styled.div`
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
  display: flex;
  align-items: center;
  .betaText {
    color: #6528f7;
    padding: 4px 7px;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.13px;
    /* width: 44px; */
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #dce6ff;
    margin-left: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ManageEditSection = styled.div`
  width: 100%;
  height: 65px;
  /* background-color: violet; */
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  img {
    cursor: pointer;
  }
`;

export const ManageShareSection = styled.div`
  width: 100%;
  padding: 24px 20px;
  &.embed {
    cursor: pointer;
  }
`;

export const Sharelink = styled.input`
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  background: #f4f6f8;
  padding: 12px 17px 12px 15px;
  text-overflow: ellipsis;
  background: #f4f6f8;
  margin-top: 20px;
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const CopyLink = styled.div`
  width: 100%;
  height: 41px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #667085;
  background: #fff;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404b59;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 3px;
  cursor: pointer;
  &:hover {
    border: 2px solid #667085;
  }
`;

export const VdoCntrlSection = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 16px;
  justify-content: space-between;
  background-color: transparent;

  div {
    display: flex;
    align-items: center;
  }
`;

export const EmptyCommentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const NoImg = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #dadbfe;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4150ab;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  text-transform: capitalize;
`;
export const TranscriptLoader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
  column-gap: 14px;
  ${theme.breakpoints.smallView} {
    gap: 18px;
    flex-direction: column;
  }
`;
export const TranscriptLoaderImg = styled.img`
  width: 24px;
  height: 24px;
  animation: ${rotateAnimation} 1s linear infinite;
`;
export const TranscriptLoading = styled.div`
  color: ${theme.colors.placeHolder};
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${theme.breakpoints.smallView} {
    font-size: 12px;
  }
`;
export const GradientDiv = styled.div`
  display: none;
  width: 100%;
  background: linear-gradient(180deg, #1e2126 0%, rgba(30, 33, 38, 0) 100%);
  height: 56px;
  flex-shrink: 0;
  position: absolute;
`;
export const SummaryLoaderView = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
  column-gap: 14px;
  position: absolute;
  top: 0;
`;
export const SummaryWrap = styled.div`
  position: relative;
`;
export const TotalGoogleSignInContainer = styled.div`
  width: 65.5%;
  height: 144px;
  border-radius: 4px;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px 0px rgba(70, 66, 66, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
`;

export const SigninContentCont = styled.div`
  color: #404b59;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const SignInButtonCont = styled.div`
  margin-top: 22px;
  width: 171px;
  height: 42px;
  cursor: pointer;
`;
export const SignInButtonImg = styled.img`
  height: 42px;
  width: auto;
`;
export const PlaybackSpeed = styled.div`
  color: white;
  .playSlider {
    /* z-index: -999; */
    visibility: ${(props) => (props.showSlider ? 'visible' : 'hidden')};
    /* transition: all 0.2s ease-in-out; */
  }

`;
export const CustomerFeedWrap = styled.div`
display: flex;
margin-top: 5%; 
width: 100%;
height: 90px;
background-color: #f4f6f8;
border-radius: 5px;
color: #FF3B30;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
padding:15px;
display: flex;
flex-direction: column;
@media screen and (max-width:1220px) {
font-size: 12px;
}
`
export const BtnsWrap= styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
align-items: center;
margin-top: 20px;
gap:10px;
@media screen and (max-width:1220px) {
margin-top: 15px;
  
}
`
export const YesBtn =styled.div`
border: none;
border-radius: 4px;
background: #7F4CF8;
display: flex;
width: 60px;
height: 30px;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
color: #FFF;
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.07px;
cursor: pointer;
@media screen and (max-width:1220px) {
width: 45px ;
height: 25px ;
}
`
export const NoBtn = styled.div`
border: none;
border-radius: 4px;
display: flex;
width: 60px;
height: 30px;
padding: 10px 16px;
justify-content: center;
align-items: center;
color: #667085;
border: 1px solid #667085;
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
cursor: pointer;
@media screen and (max-width:1220px) {
width: 45px;
height: 25px;
}
`
export const TextAreaFeed = styled.textarea`
background-color: none;
resize: none;
border: none;
height: 70%;
padding: 10px;
border-radius: 2px;
&:focus-visible{
  outline:1px solid #667085;
}
`
export const TextWrapFeed =styled.div`
display: flex;
margin-top: 5%; 
width: 100%;
height: 120px;
background-color: #f4f6f8;
border-radius: 5px;
color: #FF3B30;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
padding:15px;
display: flex;
flex-direction: column;
@media screen and (max-width:1220px) {
font-size: 12px;
}
`
export const BtnsWrapFeed= styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
align-items: center;
margin-top: 10px;
gap:10px;
`


export const PlyrVideo = styled.div`

overflow: hidden; 
position: relative; 
padding: 0; 
border-radius: 0.25rem; 
width: 100%; 
height: 100%; 
`